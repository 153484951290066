.navbar {
   z-index: 5;
   position: fixed;
   top: 0;
   width: 100%;
   padding: 20px 25px;
   background-image: linear-gradient(to right, rgb(252, 218, 164) 50%, rgb(232, 237, 224) 50%);
}
.navbar .navbar__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 35px;
   .menu {
      display: flex;
      list-style: none;
      & li {
         margin-left: 20px;
         text-transform: capitalize;
         & a {
            transition: all 0.3s ease-in;
            text-decoration: none;
            cursor: pointer;
            color: black;
            font-size: 110%;
            &:hover {
               transition: all 0.3s ease-in;
               color: rgb(204, 148, 50) !important;
            }
         }
      }
   }
}
.navbar__sticky {
   background-color: rgba(252, 218, 164, 0.425);
   color: white;
   transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
   background-image: none;
   &.navbar__sticky__burg {
      background-color: rgba(252, 218, 164, 1);
      transition: none;
   }
}

.burger {
   font-size: 25px;
   display: none;
   i {
      cursor: pointer;
   }
}
.burger__box {
   background-color: rgb(252, 218, 164);
   position: absolute;
   top: 95px;
   left: 0;
   height: 100vh;
   width: 100%;
   z-index: 20;
   display: flex;
   justify-content: center;
   overflow: hidden;
   transform: translateY(-150%);
   transition: all 0.3s ease-in-out;
   ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: -120px;
      height: 100%;
      width: 100%;
      overflow: auto;
      text-align: center;
      li {
         margin: 30px 0;
         font-size: 20px;
         &:first-child {
            margin-top: 145px;
         }
         &:last-child {
            margin-bottom: 125px;
         }
         a {
            text-transform: capitalize;
            text-decoration: none;
            color: white;
            &:hover {
               color: rgb(30, 195, 245) !important;
            }
         }
      }
   }
   &.burger__box_active {
      transform: translateY(0%);
      transition: all 0.3s ease-in-out;
   }
}

.logo img {
   width: 90px;
}

@media (max-width: 920px) {
   .menu__box {
      display: none;
   }
   .burger {
      display: block;
   }
}

@media (max-width: 550px) {
   .navbar .navbar__wrapper {
      padding-left: 0;
      padding-right: 10px;
   }
}
