.container {
   width: 100%;
   height: 100vh;
   background-color: #e982d1;
   display: flex;
   align-items: center;
   padding: 15px;
   .content {
      width: 400px;
      margin: 0 auto;
      border: 2px solid rgb(207, 39, 131);
      background-color: rgba(207, 39, 131, 0.445);
      padding: 5px;
      border-radius: 10px;
      .input__box {
         margin: 5px 0;
         input {
            width: 100%;
            padding: 10px;
            font-size: 100%;
            outline: none;
            border-radius: 5px;
            border: none;
         }
         &.passw__box {
            position: relative;
            .icon__box {
               position: absolute;
               right: 10px;
               top: 9px;
               z-index: 5;
               cursor: pointer;
               &:hover {
                  color: red;
               }
            }
         }
      }
      .btn__box {
         button {
            width: 100%;
            padding: 10px;
            font-size: 120%;
            color:rgb(237, 102, 199);
            background-color: rgb(87, 35, 70);
            border: none;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
               transition: all 0.3s ease-in-out;
               background-color:rgb(237, 102, 199);
            color: rgb(87, 35, 70);
            }
         }
      }
      .title {
         text-align: center;
         color: rgb(95, 32, 77);
      }
   }
   .error {
      font-size: 18px;
      color: rgb(197, 32, 32);
   }
}