.box__container {
   display: flex;
   justify-content: space-between;
   gap: 10px;
   .box {
      width: 100%;
      .box__text {
         color: rgb(0, 0, 0);
      }
   }
   p {
      color: rgb(204, 148, 50);
      font-weight: 600;
   }
   h3 {
      font-size: 30px;
      margin: 10px 0 20px 0;
   }
   .box__boxes {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      .box__box{
         width: 45%;
         height: 200px;
         background-color: rgb(250, 162, 98);
         border-radius: 5px;
         color: white;
         font-size: 120%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         text-align: center;
         padding: 5px;
         word-break: break-all;
         i {
            margin-bottom: 20px;
            font-size: 150%;
         }
      }
   }
   .box__form__container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      div input {
         width: 100%;
         padding: 15px 10px;
         border-radius: 5px;
         border: none;
         outline: none;
         margin-bottom: 11px;
         font-size: 16px;
         background-color: rgb(250, 162, 98);
         &::placeholder {
            color: white;
         }
      }
      .textarea {
         width: 100%;
         padding: 15px 10px;
         padding-bottom: 100px;
         border-radius: 5px;
         border: none;
         outline: none;
         margin-bottom: 12px;
         font-size: 16px;
         line-height:normal;
      }
      div button {
         width: 100%;
         padding: 15px;
         border-radius: 5px;
         border: none;
         color: white;
         font-size: 100%;
         background-color: rgb(250, 162, 98);
         cursor: pointer;
      }
      .error__text {
         color: rgb(169, 116, 116);
         margin-bottom: 5px;
      }
   }
}

@media (max-width: 630px) {
   .box__container  {
      flex-direction: column;
      row-gap: 20px;
   }
   .box__boxes {
      justify-content: space-evenly;
   }
}
@media (max-width: 430px) {
   .box__boxes {
     flex-direction: column;
     .box__box {
      width: 100% !important;
      font-size: 100% !important;
   }
   }
   .box__title {
      font-size: 20px !important;
   }
   
}