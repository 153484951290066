.services__container {
   margin: 35px 0;
   line-height: 1.6;
   h4 {
      font-size: 20px;
   }
   p {
      margin: 40px 0;
      font-size: 18px;
   }
   i {
      margin-right: 5px;
      color: rgb(20, 172, 96);
   }
}

.about__container {
   margin: 35px 0;
   line-height: 1.6;
   p {
      font-size: 18px;
   }
   .about__subtitle {
      font-size: 20px;
      margin-top: 30px;
   }
   i {
      margin-right: 5px;
      color: rgb(20, 172, 96);
   }
   .about__text {
      margin: 20px 0;
   }
   .about__othtext {
      margin-top: 30px;
   }
}