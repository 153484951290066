* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.wrapper {
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 15px;
}

.lock {
  overflow: hidden;
}

@media (max-width: 1020px) {
  .wrapper {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: 100%;
  }
  .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
  .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    display: none;
  }
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    display: none;
  }
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  min-height: 250px;
  object-fit: cover;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image  {
  max-height: 85px;
}
