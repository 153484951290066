.header {
   background-image: linear-gradient(to right, rgb(252, 218, 164) 50%, white 50%);
   padding: 15px 0;

   nav {
      background-image: none;
   }

   .imagegallery__wrapper {
      padding-top: 110px;
   }
}


