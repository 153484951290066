.first__section {
   padding: 50px 0;
   background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
      url("../../../../public/images/slider/cut.jpg");
   background-size: cover;
   .title__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
         width: 50%;
      }
      a {
         text-decoration: none;
         color: black;
      }
   }
}
.title {
   font-size: 30px;
   color: rgb(226, 166, 70);
   text-align: center;
   margin: 20px 0;
   margin-bottom: 30px;
}
.second__section {
   background-color: rgb(252, 218, 164);
   padding: 50px 0;
   .box__container {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
   }
}
.last__section {
   padding: 30px 0;
}
.box__more {
   display: flex;
   justify-content: center;
   margin-top: 25px;
}
.box__more_link {
   color: white;
   text-decoration: none;
}

@media (max-width: 630px) {
   .first__section,
   .second__section {
      padding: 20px 0;
   }
   .box__container {
      grid-template-columns: 1fr 1fr !important;
   }
   .title {
      margin: 5px 0 10px 0;
      font-size: 25px;
   }
   .first__section .title__container .title {
      width: 80%;
   }
}

@media (max-width: 430px) {
   .box__container {
      grid-template-columns: 1fr !important;
   }
   .first__section .title__container .title {
      font-size: 22px;
      width: 80%;
   }
}
