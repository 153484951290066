.section {
   margin: 20px 0;
}
.container {
   display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 630px) {
   .container {
      grid-template-columns: 1fr 1fr !important;
   }
}

@media (max-width: 430px) {
   .container {
      grid-template-columns: 1fr !important;
   }
}