.header {
   background-color: rgb(155, 18, 223);
   padding: 20px;
   .navbar__menu {
      display: flex;
      justify-content: space-evenly;
      list-style: none;
      .navbar__item {
        font-size: 25px; 
      }
      .navbar__item span{
         cursor: pointer;
         color: white;
         transition: all .3s ease-out;
         &:hover {
            color: black;
            transition: all .3s ease-out;
         }
      }
      .navbar__link {
         color: white;
         text-decoration: none;
         transition: all .3s ease-out;
         &:hover {
            color: black;
            transition: all .3s ease-out;
         }
      }
   }
}
.content {
   margin: 20px;
   font-size: 120%;
   div {
      margin: 10px 0;
   }
   .form__btn {
      padding: 10px;
      min-width: 15%;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 100%;
      background-color: rgb(114, 27, 181);
      cursor: pointer;
      transition: all .3s ease-out;
      &:hover {
         transition: all .3s ease-out;
         background-color: rgb(96, 45, 134);
         color: black;
      }
   }
   .error {
      color: red;
   }
}