.app__main {
   min-height: 100vh;
   display: flex;
   flex-direction: column;
}
.main {
   flex-grow: 1;
}
.header {
   background-color: rgb(232, 237, 224);
   .text__container {
      padding-top: 115px;
      text-align: center;
      padding-bottom: 20px;
      font-size: 20px;
      text-transform: uppercase;
      .text {
         color: rgb(226, 166, 70);
      }
   }
}
@media (max-width: 430px) {
   .text  {
      font-size: 25px;
   }
}