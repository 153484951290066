.gallery__box {
   position: relative;
   &:hover .hover__color {
      opacity: 1;
      transition: all 0.3s ease-out;
   }
   &:hover .btn {
      transform: scale(1);
      transition: all 0.3s ease-out;
   }
   .btn__delete {
      background-color: rgb(229, 61, 61);
      color: white;
      border: none;
      padding: 5px;
      cursor: pointer;
      &:hover {
         background-color: #6e2323;;
      }
   }
}
.gallery__box_img {
   display: grid;
   height: 100%;
   & img {
      object-fit: cover;
   }
}
.hover__color {
   position: absolute;
   background-color: rgba(246, 209, 179, 0.648);
   width: 95%;
   height: 95%;
   top: 2%;
   left: 3%;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   transition: all 0.3s ease-out;
   & button {
      border: none;
      border-radius: 50%;
      padding: 15px;
      background-color: rgba(248, 248, 248, 0.8);
      transform: scale(3);
      transition: all 0.3s ease-out;
      cursor: pointer;
      i {
         font-size: 20px;
         color: rgb(247, 193, 149);
      }
   }
}
.modal {
   position: fixed;
   // background-color: rgba(70, 60, 60, 0.281);
   background-color: rgba(70, 60, 60, 0.957);
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 10;
   // display: flex;
   // align-items: center;
   // justify-content: center;
   padding: 15px 0;
   &:hover {
      cursor: zoom-out;
   }
}
.modal__box {
   // margin: 0 auto;
   // border: 10px ridge rgb(252, 218, 164);
   // width: 70vw;
   // height: 80vh;
   height: 100%;
   width: 100%;
   position: relative;
   img {
      object-fit:contain;
   }
   &:hover {
      cursor: auto;
   }
}
.modal__icon {
   position: absolute;
   top: 0;
   right: 35px;
   font-size: 150%;
   i {
      font-size: 150%;
      color: rgb(101, 101, 101);
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
         color: white;
         transition: all 0.3s ease;
      }
   }
}
.modal__btn {
   position: absolute;
   width: 0;
   height: 0;
   border-top: 20px solid transparent;
   border-bottom: 20px solid transparent;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
   &.modal__btn_next {
      border-left: 20px solid rgb(101, 101, 101);
      right: 0;
      margin-right: 30px;
      transition: border-left-color .3s ease;
      &:hover {
         border-left-color:azure;
         transition: border-left-color .3s ease;
      }
   }
   &.modal__btn_prev {
      border-right: 20px solid rgb(101, 101, 101);
      left: 0;
      margin-left: 30px;
      transition: border-right-color .3s ease;
      &:hover {
         border-right-color:white;
         transition: border-right-color .3s ease;
      }
   }
}


@media (max-width: 550px) {
   .modal__btn {
      border-top-width: 15px;
      border-bottom-width: 15px;
   }
   .modal__btn .modal__btn_prev {
      border-right-width: 15px;
   } 
   .modal__btn .modal__btn_next {
      border-left-width: 15px;
   } 
}
@media (max-width: 400px) {
   .modal__icon {
      right: 15px;
   }
   .modal__btn  {
      &.modal__btn_prev {
         margin-left: 10px;
      }
      &.modal__btn_next {
         margin-right: 10px;
      }
   }
}
