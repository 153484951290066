.footer {
   background-color: rgb(252, 218, 164);
   padding: 20px;
   color: black;
   .box__cont {
      display: flex;
      justify-content: space-evenly;
      & div {
         width: 30%;
         padding: 10px;
      }
      & h3 {
         font-size: 30px;
         margin-bottom: 15px;
      }
      p {
         font-size: 18px;
         line-height: 1.6;
      }
      .icon__menu {
         margin-top: 20px;
         display: flex;
         & li {
            margin: 0 5px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(250, 162, 98);
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            & i {
               color: white;
               transition: all 0.5s ease-in-out;
            }
            &:hover {
               background-color: white;
               transition: all 0.5s ease-in-out;
            }
            &:hover i {
               color: rgb(250, 162, 98);
               transition: all 0.5s ease-in-out;
            }
         }
      }
      .second__box {
         span {
            color: rgb(204, 148, 50);
         }
         a {
            color: black;
            text-decoration: none;
            &:hover {
               color: rgb(70, 70, 70);
               text-decoration: underline;
            }
         }
         .text__marg {
            margin: 20px 0;
         }
      }
   }
}

@media (max-width: 630px) {
   .box__cont {
      flex-direction: column;
      & div {
         width: 100% !important;
      }
   }
}
@media (max-width: 430px) {
   .footer {
      padding: 10px;
   }
   .footer .box__cont .box__title {
      font-size: 25px;
      text-align: center;
   }
   .footer .icon__menu {
      justify-content: space-evenly;
   }
}
